import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import InvoicingSection from '@/app/invoicing/InvoicingSection';
import { IcontactsProps, StaffProps } from '@/types';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';
import ContactStyled from '@/components/styled/ContactStyled';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';

const ContactPerson = ({ contact }: { contact: StaffProps }) => (
  <div className="contact-person">
    <div className="contact-wrapper">
      <div className="image-container">
        {contact.photo && (
          <GatsbyImage
            image={getImage(contact.photo.gatsbyImageData)}
            alt={`${contact.firstName} ${contact.lastName} image`}
          />
        )}
      </div>

      <div className="staff-info">
        <p className="name">{`${contact.firstName} ${contact.lastName}`}</p>

        <p className="position">
          {contact.position?.title || contact?.designation || ``}
        </p>

        {contact.phoneNumber && (
          <a className="phone" href={`tel:${contact.phoneNumber}`}>
            {contact.phoneNumber}
          </a>
        )}

        <a className="email" href={`mailto:${contact.emailAddress}`}>
          {contact.emailAddress}
        </a>

        <div className="action">
          <a
            href={`${contact.linkedInProfile}`}
            className="link"
            target="_blank"
            rel="noreferrer"
            aria-label={`View ${contact.firstName} ${contact.lastName}'s LinkedIn Profile`}
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  </div>
);

const OfficeCards = ({ office }) => {
  const { title, image, address, googleMapLink, contactPerson } = office;

  return (
    <div className="office-item office-info-ph">
      <GatsbyImage image={getImage(image.gatsbyImageData)} alt="" />

      <div className="office-details">
        <div className="address">
          <h3>{title}</h3>

          <div className="office-info-fi">
            <ContentfulRawToComponent raw={address.raw} />
          </div>

          <a
            className="link"
            href={googleMapLink}
            target="_blank"
            rel="noreferrer"
            aria-label="Google Map Link"
          >
            Google Maps
          </a>
        </div>
        {contactPerson ? (
          <ContactPerson contact={contactPerson} />
        ) : (
          <div className="contact-person">
            <div className="contact-wrapper">
              <div className="image-container" />
              <div className="staff-info">
                <p className="name" />
                <p className="position" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const contacts: React.FC<IcontactsProps> = ({ data }) => {
  const contactPageMeta = data.contentfulBlock?.meta ?? {};
  const offices = data.allContentfulOffices.nodes;

  return (
    <ContactStyled>
      {usePageMeta(contactPageMeta)}

      <div className="content contact-us-page">
        <div className="container">
          <div className="page-title h1-title">
            <h1>Contact</h1>
          </div>

          <div className="main-content">
            <Reveal className="intro">
              <h1>Hi, how can we help?</h1>

              <p>
                Having a foothold on <mark>two continents</mark>, we are&nbsp;
                <mark>one team</mark> delivering award-winning digital commerce
                solutions for <mark>international clients</mark>.
              </p>

              <p>
                Schedule a 30-minute introduction with our CEO, Mikko, or reach
                out to one of our local offices
              </p>

              <div className="intro-footer">
                <div id="book-a-meet">
                  <img src="/assets/images/contact/mutte-1.jpg" alt="" />
                  <a
                    href="https://calendly.com/mikko-mantila/introduction"
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book a 30 min intro
                  </a>
                </div>

                <a href="#offices" className="link">
                  Offices
                </a>
              </div>
            </Reveal>

            <Reveal>
              <img
                src="/assets/images/contact/contact-3.jpg"
                className="contact-img"
                alt=""
              />
            </Reveal>
          </div>
        </div>
      </div>

      <Reveal id="offices" className="office-section">
        <div className="container">
          <h2>Offices</h2>

          <div className="offices">
            {offices.map((office, index) => (
              <OfficeCards key={index} office={office} />
            ))}
          </div>
        </div>
      </Reveal>

      <InvoicingSection />
    </ContactStyled>
  );
};

export const query = graphql`
  query admins {
    allContentfulStaff(
      filter: { roles: { eq: "Contact person" } }
      sort: { fields: id }
    ) {
      nodes {
        id
        countryCode
        linkedInProfile
        calendlyUrl
        firstName
        lastName
        phoneNumber
        emailAddress
        designation
        photo {
          gatsbyImageData
        }
      }
    }
    contentfulBlock(section: { eq: "contact_heading" }) {
      slug
      title
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
    allContentfulOffices(sort: { fields: order, order: ASC }) {
      nodes {
        address {
          raw
        }
        googleMapLink
        email
        title
        image {
          id
          gatsbyImageData
          title
        }
        contactPerson {
          photo {
            gatsbyImageData(
              width: 732
              outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
            )
            description
          }
          firstName
          lastName
          emailAddress
          phoneNumber
          designation
          linkedInProfile
        }
      }
    }
  }
`;

export default contacts;
